import React from "react";
import { getCurrentYear } from "../../lib/util";
import footerData from "../../queries/footer.preval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_LINKS } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import Link from "next/link";
import Image from "next/image";
import logo from "../../public/images/logo-footer.png";

const Footer = () => (
  <div
    className='w-full bg-neutral-900 text-white p-4 md:py-8 md:px-8 md:max-h-[554px] md:min-h-[554px] md:h-[554px] text-center md:text-left'
    style={{ backgroundImage: "url(/images/pattern_50.png)" }}
  >
    <div className='container grid grid-cols-1 md:grid-cols-12 gap-12 md:gap-20'>
      <div className='flex col-span-1 md:col-span-4 flex-col'>
        <Link href='/' scroll={false}>
          <Image
            src={logo}
            alt='DM Logo'
            className='mt-12 md:mt-[3.4em] cursor-pointer'
          />
        </Link>
        <p className='hidden md:block md:mt-auto'>
          <FontAwesomeIcon icon={ICON_LINKS.email} className='block-inline' />{" "}
          info@danielweb.com
        </p>
        <p className='hidden md:block md:mb-12'>
          <strong>[ {getCurrentYear()} ]</strong> - All Rights Reserved - Daniel
          Morales
        </p>
      </div>
      <div className='grid col-start-1 col-span-1 grid-cols-1 md:col-start-6 md:col-span-7 md:grid-cols-12 gap-5 md:gap-10'>
        <div className='grid grid-rows-1 col-span-1 md:grid-rows-6 md:col-span-3 gap-10 mb-6 md:mb-0'>
          <div className='row-span-1 md:row-span-2 self-end'>
            <h3 className='font-now text-4xl font-bold uppercase block'>
              Recent Projects
            </h3>
          </div>
          <div className='row-span-1 md:row-span-4'>
            <ul className='w-full flex flex-col'>
              {footerData.projects.map(({ title, uri }, i) => (
                <li
                  className='w-full mb-6 hover:scale-110 hover:font-bold transition-all duration-100 cursor-pointer'
                  key={uuidv4()}
                >
                  <Link href={uri} scroll={false}>
                    <span className='text-xl'>{title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='grid grid-rows-1 col-start-1 col-span-1 md:grid-rows-6 md:col-start-4 md:col-span-5 gap-5 md:gap-10 mb-6 md:mb-0 md:pl-20'>
          <div className='row-span-1 md:row-span-2 self-end mx-auto'>
            <h3 className='font-now text-4xl font-bold uppercase block'>
              current availabiliy
            </h3>
          </div>
          <div className='row-span-1 md:row-span-4 mx-auto'>
            <p>{footerData?.availabilityText}</p>
            <Link href={"/contact-me"} scroll={false}>
              <span className='w-3/4 flex items-center justify-center text-center font-now font-bold text-3xl border-4 uppercase h-16 mt-8 md:mt-8 mb-6 md:mb-10 hover:bg-white md:ml-0 md:mr-auto ml-auto mr-auto hover:text-black ransition-all duration-100 cursor-pointer'>
                contact
              </span>
            </Link>
          </div>
        </div>
        <div className='grid grid-rows-1 col-start-1 col-span-1 md:grid-rows-6 md:col-start-10 md:col-span-3 gap-5 md:gap-10 mb-6 md:mb-0'>
          <div className='row-span-1 md:row-span-2 self-end'>
            <h3 className='font-now text-4xl font-bold uppercase block'>
              Find me on
            </h3>
          </div>
          <div className='row-span-1 md:row-span-4'>
            <ul className='w-full flex flex-col'>
              {footerData?.links?.map(({ icon, link }, i) => (
                <li
                  className='w-full mb-6 items-center group text-xl hover:scale-110 hover:font-bold transition-all duration-100'
                  key={uuidv4()}
                >
                  {icon && (
                    <FontAwesomeIcon icon={ICON_LINKS[icon]} className='mr-2' />
                  )}
                  <a href={link.url} target={link.target}>
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className='block md:hidden md:mt-auto'>
            <p>
              <FontAwesomeIcon
                icon={ICON_LINKS.email}
                className='block-inline'
              />{" "}
              info@danielweb.com
            </p>
            <p>
              <strong>[ {getCurrentYear()} ]</strong> - All Rights Reserved -
              Daniel Morales
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
