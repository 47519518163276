import React, { useEffect } from "react";
import ButtonMenu from "../Navegation/ButtonMenu";
import router from "next/router";
import { useUIDispatchContext, useUIStateContext } from "../UIContext";

export const HeaderCommon = () => {
  const [showBackground, setShowBackground] = React.useState(true);
  const state = useUIStateContext();
  const dispatch = useUIDispatchContext();

  useEffect(() => {
    let timeoutID;
    if (!state.isMenuOpen) {
      timeoutID = setTimeout(() => setShowBackground(false), 1000);
    } else {
      setShowBackground(true);
    }
    return () => {
      clearTimeout(timeoutID);
    };
  }, [state.isMenuOpen, showBackground]);

  const onClickHandler = () => {
    dispatch({ type: "SET_MENU_STATE_CLOSE" });
    router.push("/", undefined, { scroll: false });
  };

  return (
    <div
      className='bg-black fixed w-full z-50 top-0'
      style={{
        backgroundImage: showBackground ? "" : "url(../images/pattern_50.png)",
      }}
    >
      <div
        className=' max-w-screen-2xl mx-auto h-20 flex items-center'
        id='header-page'
      >
        <div
          className='cursor-pointer flex h-full py-3 ml-auto mr-auto md:ml-0 relative before:content-[*] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:cursor-pointer'
          onClick={onClickHandler}
        >
          <object
            data='/images/DM_image.svg'
            className='h-full w-[40px] py-2'
          />
          <object
            data='/images/daniel-morales-logotype.svg'
            className='h-full w-[280px] -ml-2'
          />
        </div>
        <ButtonMenu className='ml-auto mr-auto md:mr-0' />
      </div>
    </div>
  );
};
