import React from "react";
import { HeaderHome } from "../../Header";

export function HomeLayout(props) {
  return (
    <>
      <HeaderHome />
      <main className='static bg-white'>{props.children}</main>
    </>
  );
}
