// @ts-nocheck
import React from "react";
import Link from "next/link";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import { useUIDispatchContext, useUIStateContext } from "../UIContext";
import styles from "./Navegation.module.css";
import { motion } from "framer-motion";
import classNames from "classnames";
const cx = classNames.bind(styles);

const items = {
  open: (i) => ({
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      delay: i * 0.1,
    },
  }),
  closed: {
    x: 80,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
      delayChildren: 300,
    },
  },
};

const NavLink = React.forwardRef((props, ref) => {
  const { isActive, label, path, custom } = props;
  const dispatch = useUIDispatchContext();
  const { isMenuOpen } = useUIStateContext();

  const router = useRouter();

  const handleClick = (e, path) => {
    e.preventDefault();
    dispatch({ type: "SET_MENU_STATE_CLOSE" });
    router.push(path, undefined, { scroll: false });
  };

  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      variants={items}
      custom={custom}
      initial='closed'
      ref={ref}
      className='h-[90px] flex items-center justify-center'
    >
      <div href={path} scroll={false}>
        <span
          className={cx(
            `text-center font-now md:leading-1 leading-10 md:mb-auto text-6xl font-bold text-white hover:opacity-75 block select-none cursor-pointer py-6 ${
              isMenuOpen
                ? "pointer-events-auto select-none"
                : "pointer-events-none"
            }`,
            {
              [styles.holoText]: isActive,
            }
          )}
          onClick={(e) => handleClick(e, path)}
        >
          {label}
        </span>
      </div>
    </motion.div>
  );
});

NavLink.displayName = "NavLink";
export default NavLink;
