import { motion } from "framer-motion";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useUIDispatchContext, useUIStateContext } from "../UIContext";
import { ACTIONS } from "../UIContext/consts";
import { ANIMATIONS } from "./consts";

const Overlay = () => {
  const dispatch = useUIDispatchContext();
  const state = useUIStateContext();
  const router = useRouter();

  const scrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    });
  };

  const startAnimation = () => {
    dispatch({
      type: ACTIONS.SET_OVERLAY,
      payload: true,
    });
  };

  const completeAnimation = () => {
    scrollTop();
    setTimeout(() => {
      dispatch({
        type: ACTIONS.SET_OVERLAY,
        payload: false,
      });
    }, 100);
  };

  useEffect(() => {
    router.events?.on("routeChangeStart", startAnimation);
    router.events?.on("routeChangeComplete", completeAnimation);
    router.events?.on("routeChangeError", completeAnimation);

    return () => {
      router.events?.off("routeChangeStart", startAnimation);
      router.events?.off("routeChangeComplete", completeAnimation);
      router.events?.off("routeChangeError", completeAnimation);
    };
  }, [router]);

  return (
    <motion.div
      className={`background flex flex-col h-screen w-screen z-[999] justify-center items-center bg-black fixed ${
        state.isOverlayOpen ? "pointer-events-auto" : "pointer-events-none"
      }`}
      variants={ANIMATIONS.background}
      animate={state.isOverlayOpen ? "open" : "closed"}
      initial={"closed"}
    />
  );
};

export default Overlay;
