import React, { useEffect } from "react";
import { useUIDispatchContext, useUIStateContext } from "../UIContext";
import { ACTIONS } from "../UIContext/consts";
import { motion } from "framer-motion";

const imageAnimation = {
  closed: {
    translateY: -1000,
    opacity: 0,
    transition: {
      ease: "easeOut",
    },
  },
  opened: {
    translateY: 0,
    opacity: 1,
    transition: {
      ease: "easeIn",
    },
  },
};

const bgAnimation = {
  closed: {
    opacity: 0,
    transition: {
      ease: "easeOut",
    },
  },
  opened: {
    opacity: 1,
    transition: {
      ease: "easeIn",
    },
  },
};

const MessageRecivedImage = () => {
  const state = useUIStateContext();
  const dispatch = useUIDispatchContext();
  const images = state.images || [];
  const [image, setImage] = React.useState(images[0]);

  useEffect(() => {
    setImage(images[Math.floor(Math.random() * images.length)]);
  }, [images, state.isRecivedImageShowing]);

  const closeHandler = () => {
    dispatch({ type: ACTIONS.SET_RECIVED_IMAGE, payload: false });
  };

  return (
    <motion.div
      className={`w-screen h-screen z-50 justify-center items-center flex flex-col bg-black/60 ${
        state.isRecivedImageShowing
          ? "pointer-events-auto fixed"
          : "pointer-events-none absolute"
      }`}
      onClick={closeHandler}
      animate={state.isRecivedImageShowing ? "opened" : "closed"}
      variants={bgAnimation}
    >
      <motion.div
        animate={state.isRecivedImageShowing ? "opened" : "closed"}
        variants={imageAnimation}
        className='w-screen h-screen z-50 justify-center items-center flex flex-col fixed'
      >
        <img
          src={image?.sourceUrl}
          alt='message recieved'
          className='md:w-1/3 w-11/12 h-auto border-white border-8 border-b-0 drop-shadow-card translate-z-0'
        />
        <div className='bg-white text-black font-now text-center md:w-1/3 w-11/12 block relative z-50 py-4'>
          <h4 className='text-5xl py-2 uppercase '>{state.titleMessage}</h4>
          <p className='font-regular text-2xl'>{state.bodyMessage}</p>
        </div>
      </motion.div>
    </motion.div>
  );
  ``;
};

export default MessageRecivedImage;
