import React from "react";
import { useRouter } from "next/router";
import { useUIDispatchContext, useUIStateContext } from "../UIContext";
import { motion, AnimatePresence } from "framer-motion";
import { getCurrentYear } from "../../lib/util";
import menuData from "../../queries/menu.preval";
import NavLink from "./NavLink";
import { v4 as uuidv4 } from "uuid";

const ANIMATIONS = {
  menuParent: {
    open: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.2,
        when: "afterChildren",
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: "afterChildren",
      },
    },
  },
  background: {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        duration: 1,
      },
    },
    closed: {
      opacity: 0,
      y: -1500,
      transition: {
        ease: "easeOut",
        duration: 0.9,
      },
    },
  },
};

const Nav = () => {
  const dispatch = useUIDispatchContext();
  const state = useUIStateContext();
  const router = useRouter();

  const closeMenu = () => {
    dispatch({
      type: "SET_MENU_STATE",
      payload: false,
    });
  };

  return (
    <motion.nav
      initial={false}
      animate={state.isMenuOpen ? "open" : "closed"}
      onClick={state.isMenuOpen ? closeMenu : () => {}}
    >
      <AnimatePresence>
        <motion.div
          className={`background flex flex-col h-screen w-screen z-40 justify-center items-center bg-black fixed ${
            state.isMenuOpen ? "pointer-events-auto" : "pointer-events-none"
          }`}
          variants={ANIMATIONS.background}
        >
          <motion.div className='relative mt-auto text-gray-300 md:mb-6 mb-24'>
            <p>
              <strong>[ {getCurrentYear()} ]</strong> - All Rights Reserved -
              Daniel Morales
            </p>
          </motion.div>
        </motion.div>

        <motion.div
          variants={ANIMATIONS.menuParent}
          className={`fixed flex justify-center w-screen h-screen z-50 ${
            state.isMenuOpen ? "pointer-events-auto" : "pointer-events-none"
          }`}
        >
          <div className='flex flex-col justify-evenly items-center mt-36 md:mt-auto mb-auto max-h-[560px]'>
            {menuData.map(({ path, label }, i) => {
              const activePath = router.asPath.replace(/\//g, "");
              const activePath2 = router.asPath.replace(/\//g, ".").split(".");

              const isActive =
                router.asPath === "/"
                  ? path === "/"
                  : path.includes(activePath) ||
                    (path === "/projects" && activePath2.includes("project")) ||
                    (path === "/business-ventures" &&
                      activePath2.includes("business-ventures"));
              return (
                <NavLink
                  isActive={isActive}
                  path={path}
                  label={label}
                  key={uuidv4()}
                  custom={i}
                />
              );
            })}
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.nav>
  );
};

export default Nav;
