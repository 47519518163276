import React from "react";
import Container from "../Container";
import classNames from "classnames";
import styles from "./HeaderHome.module.css";
import ButtonMenu from "../Navegation/ButtonMenu";
import ScrollDownAnimation from "../ScrollDownAnimation";
import MailchimpSubscribe from "../MailChimpForm";
import router from "next/router";

export const HeaderHome = () => {
  const onclickHandler = () => router.push("/", undefined, { scroll: false });

  return (
    <Container
      className={classNames(styles.bgSlider, "bg-black text-white py-0")}
      isFullwidth
    >
      <header className='container flex flex-col pt-16 md:pt-24 pb-8 items-start h-screen'>
        <div className='mb-auto ml-auto mr-8'>
          <ButtonMenu />
        </div>
        <div className='flex justify-center flex-col md:flex-row-reverse gap-y-6 md:gap-y-12 md:gap-x-32 px-12 2xl:justify-around w-full'>
          <button
            className='md:p-0 md:w-1/2 lg:w-1/3 self-center block relative before:content-[*] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:cursor-pointer'
            onClick={onclickHandler}
          >
            <object
              data='/images/DM_image.svg'
              className={classNames(
                styles.svgShadow,
                "w-9/12 mx-auto -mt-10 select-none"
              )}
            />
          </button>
          <div className='w-full md:w-1/2 self-center p-[1.2em] md:p-0 relative isolate'>
            <button
              onClick={onclickHandler}
              className='md:h-full after:h-1/2 md:w-full before:content-[*] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:cursor-pointer z-20'
            />
            <object
              data='/images/logo.svg'
              className='svgShadow select-none'
              onClick={onclickHandler}
            />
            <p className='pt-6 pb-4 md:py-10 text-lg text-center md:text-left'>
              {"Self-Taught Musician, Art Enthusiast & Technology Nerd."}
            </p>
            <div className='w-full mt-4 md:mt-0'>
              <MailchimpSubscribe />
            </div>
          </div>
        </div>
        <div className='mt-auto mb-24 md:mb-0 md:py-10 w-full flex justify-center text-4xl md:text-xl'>
          <ScrollDownAnimation />
        </div>
      </header>
    </Container>
  );
};
